<template>
<div>
  <div class="box">
      <div class="banner">
        <div v-if="bannerList.length>0">
          <img :src="bannerList[0].bannerImg" alt="" @click="topage(bannerList[0].h5Url)">
        </div>
        <!-- <img :src="bannerList[0].bannerImg" alt="" @click="topage(bannerList[0].h5Url)"> -->
      </div>
    <div class="mbox">
      <div class="container">
        <div class="timg">
          <!-- <img :src="docmsg.avatar" alt=""> -->
          <el-avatar :size="148" :src="docmsg.avatar"></el-avatar>
        </div>
        <div class="right">
          <div class="stitle">
            <span class="name">{{docmsg.doctorName}}</span>
              <span class="label">{{docmsg.doctorTitle}}</span>
          </div>
          <div class="t2">
            <span>{{docmsg.hospital}}</span>
            <span>{{docmsg.department}}</span>
          </div>
          <div class="good">
            <el-row type="flex">
              <el-col :span="4" style="min-width:60px">
                  擅长：
              </el-col>
              <el-col :span="21">
                <span v-html="docmsg.goodAt ? docmsg.goodAt: '无'"></span>
              </el-col>
            </el-row>
          </div>
        <div class="jianjie">
          <el-row>
              <el-col :span="4" class="t1" style="min-width:80px">
                  个人简介：
              </el-col>
              <el-col :span="20">
                <span v-html="docmsg.experiences ? docmsg.experiences : '无'"></span>
              </el-col>
            </el-row>
        </div>
        <div class="shangchang">
          <el-row type="flex">
              <el-col :span="4" class="t1" style="min-width:80px">
                  擅长领域：
              </el-col>
              <el-col :span="20">
                <span v-html="docmsg.goodAt ? docmsg.goodAt : '无'"></span>
              </el-col>
            </el-row>
        </div>
        <div class="k1" v-if="tag != ''">
          <el-row>
            <!-- <div class="kk" v-for="(item,index) in tag" :key="index" :style="{color: 'rgb('+Math.floor(Math.random() * 256) + ','+Math.floor(Math.random() * 256) + ','+Math.floor(Math.random() * 256)+')'}">{{item}}</div> -->
            <div class="kk" v-for="(item,index) in tag" :key="index" :style="{color: color[index%3]}">{{item}}</div>
          </el-row>
          </div>
        <div class="rongyu">
          <el-row type="flex">
              <el-col :span="4" class="t1"  style="min-width:80px">
                  获得荣誉：
              </el-col>
              <el-col :span="20">
                <span v-html="docmsg.honors ? docmsg.honors : '无'"></span>
              </el-col>
            </el-row>
        </div>
      </div>
      <div class="toq">
          <el-button type="primary" plain @click="todown()">向我提问</el-button>
          <!-- <a @click="todown()">向我提问</a> -->
        </div>
      </div>
    </div>
  </div>
    <bottom-bar class="bottom"></bottom-bar>
</div>
</template>

<script>
import BottomBar from 'components/content/bottombar/BottomBar'
import { getBanner } from 'network/getData'
export default {
  name: 'MoreService',
  components: {
    BottomBar
  },
  data () {
    return {
      docmsg: [],
      // tyty: ['123', '456', '789'],
      tag: [],
      bannerList: [],
      color: [
        '#40DD7F',
        '#7048E8',
        '#FFBC1F'
      ]
    }
  },
  // metaInfo () {
  //   return {
  //     meta: [
  //       { name: 'keywords', content: '科普视频,专家文章,专家音频,名医问答,查疾病,瑞尔安心、瑞尔良医、瑞尔良医app、瑞尔安心官网、瑞尔视景、瑞尔视景科技有限公司、单道动态心电记录仪、7天可穿戴心电监测设备、智能心电监测设备' },
  //       { name: 'description', content: '瑞尔安心在线平台,专家,疾病,科室等详细信息,由专家生产的科普视频,文章,音频等内容,瑞尔安心平台向全民提供“科普视频+语音问答+图文咨询+文章+大数据分析”多位一体的精准健康科普知识，满足大众多样化、多场景应用的健康科普需求。致力于通过互联网技术推动医生共享、知识共享、服务共享、经济共享，放大医生价值和健康科普内容价值，用通俗易懂的方式以及直白语言，制作出大众听得懂、记得住、学得会、用得上的健康科普知识，切实提升公众健康素养，让更多人能够健康地享受美好生活，助力全民健康。研发出多款医疗器械，实现了心脑血管病患者、高危人群得日常疾病筛查和健康管理，并配套了全程专业的健康管理服务，在国内医疗产品行业中具有很高的地位和影响。公司主要从事公司下设医学研发中心、软件开发中心、生产制造中心、营销中心、医学服务中心。' }
  //     ]
  //   }
  // },
  methods: {
    todown () {
      this.$router.push('/moreservice/downapp')
    },
    async getBannerList (type) {
      try {
        const result = await getBanner(type)
        this.bannerList = result.data.data
        // console.log('this.bannerList', this.bannerList)
      } catch (error) {
        // console.log(error)
      }
    },
    topage (e) {
      if (e !== 'empty') {
        window.open(e)
      }
    }
  },
  mounted () {
    this.getBannerList('doctor')
    this.docmsg = JSON.parse(sessionStorage.getItem('data'))
    // console.log(this.docmsg)
    this.tag = this.docmsg.tag.split('、')
  }
}
</script>

<style scoped>
.box{
  width: 1440px;
  /* background: #FAFAFA; */
  margin: 0 auto;
}
.banner{
    width: 1440px;
    height: 200px;
    /* background: #E8E8E8; */
    /* background-image: url("~assets/banner.png"); */
}
.banner img{
    width: 1440px;
    height: 200px;
}
::v-deep .el-avatar>img{
  height: unset;
  width: 100%;
}
.mbox{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 1440px;
  /* margin-bottom: 200px; */
}
.container{
  width: 1200px;
  background: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  display: flex;
  padding-bottom:200px ;
}
.bottom{
    /* width: 1440px; */
    /* margin-top: 200px; */
    width: 100%;
    /* height: 594px; */
    background: #FFFFFF;
    flex: none;
    order: 1;
    flex-grow: 0;
    /* margin: 30px 0px; */
}
.timg{
  width: 156px;
  height: 156px;
  margin-left: 100px;
  margin-top: 24px;
  border-radius: 50%;
}
.timg img{
  border-radius: 50%;
  /* width: 148px; */
  /* height: 148px; */
  width: 100%;
  height: 100%;
  left: 5px;
  top: 4px;
  /* box-sizing: border-box; */
}
.right {
  margin-left: 34px;
}
.stitle{
  margin-top: 29px;
  /* height: 24px; */
  font-size: 16px;
  display: flex;
}
.stitle .name{
  /* height: 24px; */
  font-size: 16px;
  margin-right: 14px;
  color: #262626;
}
.stitle .label{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
/* width: 103px; */
height: 20px;
line-height: 24px;
padding: 10px 5px;
color:  #4263EB;
border: 1px solid #4263EB;
box-sizing: border-box;
border-radius: 4px;
}
.t2{
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: 22px;
  font-size: 14px;
  line-height: 22px;
  margin-top: 20px;
  color: #595959;
}
.t2 span{
  margin-right: 10px;
}
.good{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  width: 428px;
  font-size: 14px;
  line-height: 22px;
  color: #595959;
  /* height: 22px; */
  margin-top: 20px;
}
.toq{
  width: 120px;
  height: 60px;
  margin-top: 84px;
  margin-left: 70px;
  /* border: 2px solid #F6A609; */
  box-sizing: border-box;
  border-radius: 10px;
}

.toq .cen {
  margin: 0 auto;
}
.toq a{
  cursor: pointer;
  text-decoration: none;
  font-size: 20px;
  line-height: 28px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #F6A609;
}
.jianjie{
  /* display: flex; */
  /* flex-direction: row; */
  /* align-items: flex-start; */
  margin-top: 20px;
  width: 627px;
  /* height: 110px; */
  color: #595959;
}
.shangchang{
  /* display: flex;
  flex-direction: row;
  align-items: flex-start; */
  margin-top: 20px;
  width: 546px;
  /* height: 22px; */
  color: #595959;
}
.rongyu{
  /* display: flex;
flex-direction: row;
align-items: flex-start; */
padding: 0px;
color: #595959;
margin-top: 20px;
/* width: 336px; */
/* height: 264px; */
}
.t1{
  font-size: 14px;
line-height: 22px;
width: 90px;
height: 22px;
color: #595959;
}
.t3{
  width: 527px;
/* height: 110px; */
  font-size: 14px;
line-height: 22px;
color: #595959;
}
.t4{
  width: 266px;
height: 244px;
left: 70px;
top: 0px;
  font-size: 14px;
line-height: 22px;
color: #595959;
}
.kk{
  float: left;
  margin-left: 18px;
  padding: 0 5px;
  line-height: 24px;
  border: 1px solid;
  border-radius: 4px;
}
.k1{
  display: inline-block;
  margin: 20px 80px;
}
.goat{
  width: 100px;
}
</style>
